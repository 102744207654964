import type { Theme } from '@material-ui/core';
import { CircularProgress, createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Card, i18n, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useState } from 'react';

import { DocumentUploadDocument, EnrichedDocument } from '../../rxdb/Documents/queryBuilder';
import useDocsForLocation from '../../rxdb/Documents/useDocsForLocation';
import useGlobalDocs from '../../rxdb/Documents/useGlobalDocs';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import usePrediction from '../../rxdb/Prediction/usePrediction';
import { useNotification } from '../../utilities';
import { getAttachmentFromIndexDB } from '../CacheUpdateHandler/documentUtilities';
import DocumentThumbnail from '../DocumentThumbnail';
import ImagePreviewModal from '../ImagePreviewModal';
import PDFPreviewModal from '../PDFPreviewModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subCard: {
      margin: '0px 0px 10px 0px !important',
      padding: '16px',
    },
    type: {
      color: theme.palette.common.white,
    },
    spacer: {
      marginLeft: 15,
    },
    spinner: {
      color: `${theme.palette.primary.main} !important`,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    verticalSpacer: {
      marginTop: 18,
    },
  }),
);

const BorerShiftDocumentsCard: FC = () => {
  const classes = useStyles();

  const [pdfWindow, setPdfWindow] = useState<WindowProxy | undefined>();

  const { currentRoom, currentSurveyPoint, currentBorer } = useCurrentBorer();

  const { predictedEndLocation } = usePrediction();

  const {
    docsForLocation: currentLocationDocs,
    docsForLocationLoaded: docsForCurrentLocationLoaded,
  } = useDocsForLocation(currentRoom?.id, currentSurveyPoint?.description);

  const {
    docsForLocation: predictionEndLocationDocs,
    docsForLocationLoaded: docsForPredictionLoaded,
  } = useDocsForLocation(
    predictedEndLocation?.room?.id,
    predictedEndLocation?.surveyPoint?.description,
  );

  const globalDocRoomArray = useMemo(
    () => [predictedEndLocation?.room, currentRoom],
    [predictedEndLocation, currentRoom],
  );
  const { globalDocs, globalDocsLoaded } = useGlobalDocs(globalDocRoomArray);

  const { errorNotification } = useNotification();

  const [imagePreviewModalOpen, setImagePreviewModalOpen] = useState(false);
  const [PDFPreviewModalOpen, setPDFPreviewModalOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<EnrichedDocument | undefined>();
  const [selectedDocumentData, setSelectedDocumentData] = useState<string | undefined>();

  const endPredictionString = useMemo(() => {
    let str = '';

    if (predictedEndLocation?.block?.description) {
      str += predictedEndLocation.block.description;
    }
    if (predictedEndLocation?.panel?.description) {
      str += predictedEndLocation.panel.description;
    }
    if (predictedEndLocation?.room?.description) {
      str += predictedEndLocation?.room?.description;
    }
    if (predictedEndLocation?.surveyPoint?.description) {
      str += predictedEndLocation?.surveyPoint?.description;
    }

    return str;
  }, [predictedEndLocation]);

  const selectDocument = async (document: DocumentUploadDocument, windowProxy?: WindowProxy) => {
    let url = null;

    if (document.isCached && document.fileName) {
      // If we have the doc cached, get it from the cache
      url = await getAttachmentFromIndexDB(document.fileName);
    }

    if (url) setSelectedDocumentData(url);
    else {
      errorNotification(
        'Error opening document. Please try again or check your network connection.',
      );
      return;
    }

    setImagePreviewModalOpen(true);
    setSelectedDocument(document);

    if (windowProxy) {
      setPdfWindow(windowProxy);
      setPDFPreviewModalOpen(true);
    } else {
      setImagePreviewModalOpen(true);
    }
  };

  const unselectDocument = () => {
    setImagePreviewModalOpen(false);
    setPDFPreviewModalOpen(false);
    setSelectedDocumentData(undefined);
    setPdfWindow(undefined);
  };

  const prepareToSelectDocument = (doc: DocumentUploadDocument) => {
    if (doc.fileName?.toLowerCase().includes('.pdf')) {
      const windowProxy = window.open();
      selectDocument(doc, windowProxy || undefined);
    } else {
      selectDocument(doc);
    }
  };

  const getDocumentCards = () => {
    const cards: JSX.Element[] = [];

    cards.push(
      <Card
        elevation={2}
        className={classes.subCard}
        data-testid={`documents-borer-${currentBorer?.shortName}`}
        key={`documents-borer-${currentBorer?.shortName}`}
      >
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" className={classes.type}>
              {i18n.t('Documents')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {docsForCurrentLocationLoaded && (
            <>
              <Grid container className={classes.verticalSpacer}>
                {currentLocationDocs?.map(document => {
                  return (
                    <DocumentThumbnail
                      document={document}
                      selectDocument={prepareToSelectDocument}
                      key={document.id}
                    />
                  );
                })}
                {currentLocationDocs?.length === 0 && (
                  <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
                )}
              </Grid>
            </>
          )}
          {!docsForCurrentLocationLoaded && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>,
    );

    // If end prediction has docs & location is different than current borer, show them
    if (
      endPredictionString &&
      (predictedEndLocation?.room?.id !== currentRoom?.id ||
        predictedEndLocation?.surveyPoint?.id !== currentSurveyPoint?.id)
    ) {
      cards.push(
        <Card
          elevation={2}
          className={classes.subCard}
          key="endPredictionDocs"
          data-testid="endPredictionDocs"
        >
          <Grid container justify="space-between">
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.type}>
                {i18n.t('Documents - {{endPredictionString}}', {
                  endPredictionString,
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justify="flex-start">
            {docsForPredictionLoaded && (
              <>
                <Grid container className={classes.verticalSpacer}>
                  {predictionEndLocationDocs?.map(document => {
                    return (
                      <DocumentThumbnail
                        document={document}
                        selectDocument={prepareToSelectDocument}
                        key={document.id}
                      />
                    );
                  })}
                  {predictionEndLocationDocs?.length === 0 && (
                    <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
                  )}
                </Grid>
              </>
            )}
            {!docsForPredictionLoaded && <CircularProgress className={classes.spinner} />}
          </Grid>
        </Card>,
      );
    }

    return cards;
  };

  return (
    <>
      {getDocumentCards()}
      <Card elevation={2} className={classes.subCard}>
        <Grid container justify="space-between">
          <Grid item xs={4}>
            <Typography
              variant="h5"
              className={classes.type}
              data-testid="global-document-card-header"
            >
              {i18n.t('Global documents')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justify="flex-start">
          {globalDocsLoaded && (
            <>
              <Grid container className={classes.verticalSpacer}>
                {globalDocs?.map(document => {
                  return (
                    <DocumentThumbnail
                      document={document}
                      selectDocument={prepareToSelectDocument}
                      key={document.id}
                    />
                  );
                })}
                {globalDocs?.length === 0 && (
                  <Typography variant="body1">{i18n.t('No documents to show')}</Typography>
                )}
              </Grid>
            </>
          )}
          {!globalDocsLoaded && <CircularProgress className={classes.spinner} />}
        </Grid>
      </Card>

      <ImagePreviewModal
        open={imagePreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
      />
      <PDFPreviewModal
        open={PDFPreviewModalOpen}
        onClose={unselectDocument}
        url={selectedDocumentData ?? ''}
        imageTitle={selectedDocument?.documentTypeInformation?.description ?? ''}
        pdfWindow={pdfWindow}
      />
    </>
  );
};

export default observer(BorerShiftDocumentsCard);
