import { Grid } from '@material-ui/core';
import { Button, Card, i18n, Icons, Typography } from '@nutrien/cxp-components';
import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useState } from 'react';

import Spinner from '@/components/Spinner';
import { useMst } from '@/mobx-models/Root';
import { useBorerShiftComment } from '@/rxdb/BorerShiftComment/useBorerShiftComment';
import { BORER_SHIFT_WRITE_PERMISSION, EMPTY_HTML_COMMENT } from '@/utilities/constants';
import { capitalizeFirstLetterOnly } from '@/utilities/utilityFunctions';

import ModifyCommentSidePanel from '../ModifyCommentSidePanel';
import useStyles from './CommentCard.styles';

const CommentCard: FC = () => {
  const classes = useStyles();
  const { user } = useMst();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const toggleSidePanel = () => {
    setSidePanelOpen(prev => !prev);
  };

  const { borerShiftCommentsLoaded, borerShiftComments } = useBorerShiftComment();

  const existingComments = useMemo(
    () =>
      Array.from(borerShiftComments.values()).some(
        bsComment => bsComment?.comment !== EMPTY_HTML_COMMENT && bsComment?.comment !== undefined,
      ),
    [borerShiftComments],
  );

  return (
    <>
      <Card className={classes.cardRoot}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h5">{i18n.t('Comment')}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.editBtnContainer}>
            {user.hasPermissionTo(BORER_SHIFT_WRITE_PERMISSION) && (
              <Button
                color="primary"
                variant="outlined"
                onClick={toggleSidePanel}
                className={classes.addOrEditButton}
                disabled={!borerShiftCommentsLoaded}
                id="edit-comment-button"
              >
                <Icons.EditFeather color="primary" />
                {existingComments ? i18n.t('Edit comment') : i18n.t('Add comment')}
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!borerShiftCommentsLoaded ? (
              <Spinner />
            ) : (
              borerShiftComments?.size > 0 &&
              Array.from(borerShiftComments.entries()).map(([description, comment]) => {
                return (
                  <Grid container spacing={2} key={description} className={classes.commentRow}>
                    <Grid item xs={4}>
                      <Typography className={classes.label} variant="body2">
                        {capitalizeFirstLetterOnly(description)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <span className={classes.commentText}>{comment?.parsedComment}</span>
                    </Grid>
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      </Card>
      <ModifyCommentSidePanel
        open={sidePanelOpen}
        setSidePanelOpen={setSidePanelOpen}
        commentsToEdit={borerShiftComments}
        loading={!borerShiftCommentsLoaded}
      />
    </>
  );
};

export default observer(CommentCard);
