import { SiteIds } from '@nutrien/minesight-utility-module';
import { useCallback, useMemo } from 'react';
import { useRxData } from 'rxdb-hooks';

import { useMst } from '@/mobx-models/Root';
import {
  AllanCommentTypes,
  CommentType,
  CoryCommentTypes,
  LaniganCommentTypes,
  RocanvilleAutomationCommentTypes,
  RocanvilleCommentTypes,
  VanscoyCommentTypes,
} from '@/utilities/constants';

import RxdbCollectionName from '../rxdbCollectionName';
import { BorerShiftCommentType, BorerShiftCommentTypeDocument } from './queryBuilder';

const getCommentTypeDescriptionsForSite = (siteId: string): CommentType[] => {
  switch (siteId) {
    case SiteIds.Rocanville:
      return RocanvilleCommentTypes;
    case SiteIds.Lanigan:
      return LaniganCommentTypes;
    case SiteIds.Vanscoy:
      return VanscoyCommentTypes;
    case SiteIds.Cory:
      return CoryCommentTypes;
    case SiteIds.Allan:
      return AllanCommentTypes;
    default:
      return [];
  }
};

const getAutomationCommentTypeDescriptionsForSite = (siteId: string): CommentType[] => {
  switch (siteId) {
    case SiteIds.Rocanville:
      return RocanvilleAutomationCommentTypes;
    default:
      return [];
  }
};

const getBorerShiftCommentTypes = (
  commentTypeDescriptions: string[],
  bsCommentTypeDocs: BorerShiftCommentTypeDocument[],
): BorerShiftCommentTypeDocument[] => {
  const borerShiftCommentTypes: BorerShiftCommentTypeDocument[] = [];

  commentTypeDescriptions.forEach(commentTypeDescription => {
    const bsCommentTypeDoc = bsCommentTypeDocs.find(
      commentTypeDoc =>
        commentTypeDoc.description.toLowerCase() === commentTypeDescription.toLowerCase(),
    );
    if (bsCommentTypeDoc) {
      borerShiftCommentTypes.push(bsCommentTypeDoc);
    }
  });
  return borerShiftCommentTypes;
};

interface BorerShiftCommentTypeHook {
  borerShiftcommentTypesLoaded: boolean;
  borerShiftCommentTypesArray: BorerShiftCommentTypeDocument[];
  automationCommentTypesArray: BorerShiftCommentTypeDocument[];
}

export const useBorerShiftCommentType = (): BorerShiftCommentTypeHook => {
  const { user } = useMst();

  const borerShiftCommentTypeQueryConstructor = useCallback(collection => collection.find(), []);

  const { result: borerShiftCommentTypeDocs, isFetching: borerShiftCommentTypeDocsFetching } =
    useRxData<BorerShiftCommentType>(
      RxdbCollectionName.BORER_SHIFT_COMMENT_TYPE,
      borerShiftCommentTypeQueryConstructor,
    );

  const borerShiftCommentTypesArray = useMemo((): BorerShiftCommentTypeDocument[] => {
    if (user.siteId) {
      const ctDescriptions = getCommentTypeDescriptionsForSite(user.siteId);
      return getBorerShiftCommentTypes(ctDescriptions, borerShiftCommentTypeDocs);
    }
    return [];
  }, [user.siteId, borerShiftCommentTypeDocs]);

  const automationCommentTypesArray = useMemo((): BorerShiftCommentTypeDocument[] => {
    if (user.siteId) {
      const ctDescriptions = getAutomationCommentTypeDescriptionsForSite(user.siteId);
      return getBorerShiftCommentTypes(ctDescriptions, borerShiftCommentTypeDocs);
    }
    return [];
  }, [user.siteId, borerShiftCommentTypeDocs]);

  return {
    borerShiftcommentTypesLoaded: !borerShiftCommentTypeDocsFetching,
    borerShiftCommentTypesArray,
    automationCommentTypesArray,
  };
};

export default useBorerShiftCommentType;
