import { BorerShiftCrewMemberWithRole } from '@/rxdb/BorerShiftCrew/queryBuilder';

export const duplicateEmployeeAndRole = (
  crewMemberBeingValidated: BorerShiftCrewMemberWithRole,
  crewMemberList: BorerShiftCrewMemberWithRole[],
) => {
  if (!crewMemberBeingValidated?.employee?.id) return false;
  const membersWithMatchingEmployeeId = crewMemberList.filter(
    crewMember => crewMember.employee?.id === crewMemberBeingValidated.employee?.id,
  );
  const employeeRoleCombination = membersWithMatchingEmployeeId.map(
    crewMember => `${crewMember.employee?.id}-${crewMember.borerShiftCrewMemberRole?.id}`,
  );
  const hasDuplicates = new Set(employeeRoleCombination).size !== employeeRoleCombination.length;
  return hasDuplicates;
};
