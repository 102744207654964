import localforage from 'localforage';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useRxCollection } from 'rxdb-hooks';

import {
  DocumentUploadCollection,
  DocumentUploadDocument,
} from '../../rxdb/Documents/queryBuilder';
import useDocsForLocation from '../../rxdb/Documents/useDocsForLocation';
import { useCurrentBorer } from '../../rxdb/Equipment/useCurrentBorer';
import { EquipmentDeficiencyAttachment } from '../../rxdb/EquipmentDeficiencyAttachments/queryBuilder';
import useEquipmentDeficiencyAttachments from '../../rxdb/EquipmentDeficiencyAttachments/useEquipmentDeficiencyAttachments';
import { GroundHazardAttachment } from '../../rxdb/GroundHazardAttachment/queryBuilder';
import useGroundHazardAttachments from '../../rxdb/GroundHazardAttachment/useGroundHazardAttachments';
import usePrediction from '../../rxdb/Prediction/usePrediction';
import RxdbCollectionName from '../../rxdb/rxdbCollectionName';
import { useFileStorage, useOnlineStatus } from '../../utilities';
import { SYNC_COMPLETE_KEY, UPLOAD_RETRY } from '../../utilities/constants';
import useEventTracking from '../../utilities/hooks/useEventTracking';
import { getAttachmentWithRetry } from './getAttachmentWithRetry';

const DEBUG = sessionStorage.getItem('DEBUG') === 'true';

export const defaultProtectionLevel = 'public';

export const attachmentStore = localforage.createInstance({
  name: 'attachment-store',
});

const checkIfGlobalDoc = (doc: DocumentUploadDocument): boolean => {
  // Check if global doc
  if (!doc.roomId && doc.isActive && !doc.isDeleted) {
    return true;
  }

  return false;
};

const CacheUpdateHandler: React.FC = () => {
  const online = useOnlineStatus();
  const { uploadFilesInCache } = useFileStorage();
  const { sendCachedEvents } = useEventTracking();

  const { predictionCollection, predictedEndLocation } = usePrediction();
  const { currentRoom, currentSurveyPoint, miningMethod } = useCurrentBorer();

  const { docsForLocation: currentLocationDocs } = useDocsForLocation(
    currentRoom?.id,
    currentSurveyPoint?.description,
  );

  const { docsForLocation: predictionEndLocationDocs } = useDocsForLocation(
    predictedEndLocation?.room?.id,
    predictedEndLocation?.surveyPoint?.description,
  );

  const documentUploadCollection: DocumentUploadCollection = useRxCollection(
    RxdbCollectionName.DOCUMENT_UPLOAD,
  );

  const { groundHazardAttachmentsInitialized, subscribeToGroundHazardAttachments } =
    useGroundHazardAttachments();
  const { subscribeToEquipmentDeficiencyAttachments, deficiencyAttachmentsInitialized } =
    useEquipmentDeficiencyAttachments();

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (online) {
      interval = setInterval(() => uploadFilesInCache(), UPLOAD_RETRY);
      sendCachedEvents();
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [online]);

  // Ground Hazard Attachments
  const onNewGroundHazardAttachments = async (attachments: GroundHazardAttachment[]) => {
    attachments.forEach(async attachment => {
      await getAttachmentWithRetry(attachment);
    });
  };

  useEffect(() => {
    if (groundHazardAttachmentsInitialized) {
      subscribeToGroundHazardAttachments(onNewGroundHazardAttachments);
    }
  }, [groundHazardAttachmentsInitialized]);

  // Equipment Deficiency Attachment
  const onNewEquipmentDeficiencyAttachments = async (
    attachments: EquipmentDeficiencyAttachment[],
  ) => {
    attachments.forEach(async attachment => {
      await getAttachmentWithRetry(attachment);
    });
  };

  useEffect(() => {
    if (deficiencyAttachmentsInitialized) {
      subscribeToEquipmentDeficiencyAttachments(onNewEquipmentDeficiencyAttachments);
    }
  }, [deficiencyAttachmentsInitialized]);

  const onDocumentChange = async (attachment: DocumentUploadDocument | null | undefined) => {
    if (!attachment || !attachment.fileName) return;

    if (checkIfGlobalDoc(attachment)) {
      if (DEBUG) console.info('Cache this file', attachment.fileName);
      await getAttachmentWithRetry(attachment);
    } else {
      if (DEBUG) console.info("Don't cache this file", attachment.fileName);
    }

    await Promise.all([cacheGlobalDocs(), cacheDocumentsForBorerLocation()]);
  };

  useEffect(() => {
    const allDocsSub = documentUploadCollection?.insert$.subscribe(doc => {
      // Doc won't cache without this
      setTimeout(() => {
        if (doc.documentData.isCached !== true) {
          onDocumentChange(doc.documentData);
        }
      }, 1000);
    });

    return () => {
      allDocsSub?.unsubscribe();
    };
  }, [documentUploadCollection]);

  /**
   * Get the documents for the provided room id and cache them
   *
   * @param {string} roomId
   */
  const cacheDocumentsForBorerLocation = async () => {
    currentLocationDocs?.forEach(doc => {
      getAttachmentWithRetry(doc);
    });
    predictionEndLocationDocs?.forEach(doc => {
      getAttachmentWithRetry(doc);
    });
  };

  const cacheGlobalDocs = async () => {
    // Cache global docs
    if (miningMethod) {
      const uncachedGlobalDocs = await documentUploadCollection
        ?.find({
          selector: {
            isCached: false,
            roomId: null,
            isActive: true,
            miningMethod,
            retryCount: { $lt: 5 },
          },
        })
        .exec();

      uncachedGlobalDocs?.forEach(doc => {
        if (doc?.fileName) getAttachmentWithRetry(doc);
      });
    }
  };

  // When the currently viewed shift changes, check if there are any documents that need to be cached
  // Also subscribe to prediction changes
  useEffect(() => {
    const sub = predictionCollection?.$.subscribe(() => {
      if (online) {
        cacheDocumentsForBorerLocation();
        cacheGlobalDocs();
      }
    });

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, [online]);

  // Cache document for the borers current location
  useEffect(() => {
    const onSyncComplete = event => {
      if (event.data === SYNC_COMPLETE_KEY) {
        localStorage.setItem(SYNC_COMPLETE_KEY, 'true');
        setTimeout(() => {
          cacheGlobalDocs();
          cacheDocumentsForBorerLocation();
        }, 500);
      }
    };

    window.addEventListener('message', onSyncComplete, true);

    if (online) {
      cacheGlobalDocs();
      cacheDocumentsForBorerLocation();
    }

    return () => window.removeEventListener('message', onSyncComplete, true);
  }, [
    online,
    currentRoom,
    currentSurveyPoint,
    miningMethod,
    currentLocationDocs,
    predictionEndLocationDocs,
  ]);

  return null;
};

export default observer(CacheUpdateHandler);
