import { DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import { Button, i18n, Icons, SidePanel, Typography } from '@nutrien/cxp-components';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 } from 'uuid';

import {
  BorerShiftCrewMemberEmployee,
  BorerShiftCrewMemberWithRole,
} from '@/rxdb/BorerShiftCrew/queryBuilder';
import { BorerShiftCrewMemberRole } from '@/rxdb/BorerShiftCrewMemberRole/queryBuilder';

import { useMst } from '../../mobx-models/Root';
import { BorerShiftCrewType } from '../../rxdb/BorerShift/queryBuilder';
import useBorerShiftCrew from '../../rxdb/BorerShiftCrew/useBorerShiftCrew';
import useEmployees from '../../rxdb/Employees/useEmployees';
import { useNotification } from '../../utilities';
import DiscardDraftModal from '../DiscardDraftModal';
import EditCrewDetailsPanel from '../EditCrewDetailsPanel';
import { CrewEmployeePanelOperation } from '../EditCrewDetailsPanel/EditCrewDetailsPanel';
import { useStyles } from './ModifyCrewSidePanel.styles';

interface Props {
  open: boolean;
  setModifyCrewSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  crewToEdit?: BorerShiftCrewType | null;
  crewNumber?: number;
  triggerRefresh: () => void;
}

export const generateDefaultEmployee = (
  borerShiftCrewMemberId?: string,
  reactKey?: string,
): BorerShiftCrewMemberWithRole => {
  return {
    employee: {
      isActive: true,
      firstName: '',
      lastName: '',
      id: '',
      crew: '',
      department: '',
      email: '',
      phoneNumber: '',
      position: '',
      site: '',
      updatedAt: 0,
      version: 1,
      isDeleted: false,
      reactKey: reactKey || v4(),
      borerShiftCrewMemberId: borerShiftCrewMemberId || undefined,
    },
    borerShiftCrewMemberRole: null,
  };
};

const ModifyCrewSidePanel = ({
  open,
  setModifyCrewSidePanelOpen,
  crewToEdit,
  crewNumber,
  triggerRefresh,
}: Props) => {
  const classes = useStyles();
  const { shiftPicker } = useMst();

  const { employeesList } = useEmployees({
    isActive: true,
    onlyConstructionAndProduction: false,
    populateCrew: true,
    populatedPosition: true,
    onlyActiveCrew: true,
  });
  const { successNotification, errorNotification, closeSnackbar } = useNotification();

  const generateDefaultCrew = (): BorerShiftCrewType => {
    return {
      borerShiftCrewMemberInput: [
        generateDefaultEmployee(),
        generateDefaultEmployee(),
        generateDefaultEmployee(),
      ],
      borerShiftId: shiftPicker.currentBorerShiftId || '',
      endDateTime: shiftPicker.selectedShift?.shiftEnd || '',
      startDateTime: shiftPicker.selectedShift?.shiftStart || '',
      id: v4(),
      version: 1,
      crewNumber: crewNumber || 1,
    };
  };
  const [crewDetails, setCrewDetails] = useState<BorerShiftCrewType>(
    crewToEdit || generateDefaultCrew(),
  );
  const [validationIndex, setValidationIndex] = useState(0);
  const [originalCrewDetails, setOriginalCrewDetails] = useState<BorerShiftCrewType>(
    crewToEdit || generateDefaultCrew(),
  );
  const [validationError, setValidationError] = useState(false);
  const [discardOpen, setDiscardOpen] = useState<boolean>(false);
  const [undo, setUndo] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);
  const deleteMode = useRef(false);
  const { updateBorerShiftCrew, deleteBorerShiftCrew } = useBorerShiftCrew();

  const onCrewEmployeeChange = (
    index: number,
    value: BorerShiftCrewMemberEmployee | CrewEmployeePanelOperation,
  ) => {
    // Validation index is the order of the dropdowns.
    // The first two are for crew time, and each next set of 2
    // are for role and employee, respectively.
    setValidationIndex(prev => (prev < (index + 1) * 2 + 2 ? (index + 1) * 2 + 2 : prev));
    setCrewDetails(prev => {
      const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
      if (value === CrewEmployeePanelOperation.AddEmployee) {
        crewMemberCopy.push(generateDefaultEmployee());
      } else if (value === CrewEmployeePanelOperation.DeleteEmployee) {
        crewMemberCopy.splice(index, 1);
      } else {
        const existingReactKey = crewMemberCopy[index]?.employee?.reactKey;
        const newValue = {
          ...crewMemberCopy[index],
          employee:
            value === null
              ? generateDefaultEmployee(crewMemberCopy[index]?.employee?.borerShiftCrewMemberId)
              : { ...value, reactKey: existingReactKey },
        };
        crewMemberCopy[index] = newValue;
      }
      return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
    });
  };

  const onCrewEmployeeRoleChange = (index: number, value: BorerShiftCrewMemberRole | null) => {
    setValidationIndex(prev => (prev < (index + 1) * 2 + 1 ? (index + 1) * 2 + 1 : prev));
    setCrewDetails(prev => {
      const crewMemberCopy = prev.borerShiftCrewMemberInput.slice();
      const newValue = { ...crewMemberCopy[index], borerShiftCrewMemberRole: value };
      crewMemberCopy[index] = newValue;
      return { ...prev, borerShiftCrewMemberInput: crewMemberCopy };
    });
  };

  const errorSavingCrew = () => {
    errorNotification(i18n.t('Crew could not be saved. Please try again.'));
    setSaving(false);
  };

  const onCrewDetailsChange = (
    key: keyof BorerShiftCrewType,
    value: BorerShiftCrewMemberEmployee | Dayjs | string,
  ) => {
    if (key === 'startDateTime') {
      setValidationIndex(prev => (prev < 1 ? 1 : prev));
    }
    if (key === 'endDateTime') {
      setValidationIndex(prev => (prev < 2 ? 2 : prev));
    }
    setCrewDetails(prev => {
      return { ...prev, [key]: value };
    });
  };

  const hasEdits = useMemo(() => {
    if (originalCrewDetails.startDateTime !== crewDetails.startDateTime) {
      return true;
    }
    if (originalCrewDetails.endDateTime?.hour() !== crewDetails.endDateTime?.hour()) {
      return true;
    }
    if (originalCrewDetails.endDateTime?.minute() !== crewDetails.endDateTime?.minute()) {
      return true;
    }
    if (
      crewDetails?.borerShiftCrewMemberInput?.find((crewMember, index) => {
        return (
          crewMember?.employee?.id !==
            originalCrewDetails?.borerShiftCrewMemberInput[index]?.employee?.id ||
          crewMember?.borerShiftCrewMemberRole?.id !==
            originalCrewDetails?.borerShiftCrewMemberInput[index]?.borerShiftCrewMemberRole?.id
        );
      })
    ) {
      return true;
    }
    if (
      crewDetails.borerShiftCrewMemberInput.length !==
      originalCrewDetails.borerShiftCrewMemberInput.length
    ) {
      return true;
    }

    return false;
  }, [crewDetails, originalCrewDetails]);

  useEffect(() => {
    if (open && !undo) {
      setCrewDetails(crewToEdit || generateDefaultCrew());
      setOriginalCrewDetails(crewToEdit || generateDefaultCrew());
      setValidationIndex(0);
    }

    if (open) {
      setUndo(false);
    }
  }, [open, crewToEdit]);

  const onSave = async () => {
    try {
      await updateBorerShiftCrew(crewDetails, originalCrewDetails);
      triggerRefresh();
      successNotification(
        crewDetails.crewNumber === 2
          ? i18n.t('Operating crew 2 updated')
          : i18n.t('Operating crew updated'),
      );
      setSaving(false);
      setModifyCrewSidePanelOpen(false);
    } catch (error) {
      errorSavingCrew();
    }
  };

  const openDiscardEditsModel = () => {
    if (hasEdits) {
      setDiscardOpen(true);
    } else {
      setModifyCrewSidePanelOpen(false);
    }
  };

  const onDelete = useCallback(() => {
    deleteMode.current = true;
    setDiscardOpen(true);
  }, []);

  const validateCanSave = useMemo(() => {
    if (!hasEdits) return false;
    if (validationError) return false;
    if (
      crewDetails.borerShiftCrewMemberInput.find(
        crewMember => !crewMember.employee?.id || !crewMember.borerShiftCrewMemberRole?.id,
      )
    )
      return false; // All crew members must have an employee and role assigned
    return true;
  }, [hasEdits, validationError, crewDetails.borerShiftCrewMemberInput]);

  if (!open) return null;
  return (
    <>
      <SidePanel
        open={open}
        onClose={openDiscardEditsModel}
        transitionDuration={200}
        disableBackdropClick
        ModalProps={{
          BackdropProps: {
            onClick: event => {
              event.preventDefault();
            },
            onTouchStart: event => {
              event.preventDefault();
            },
            style: { touchAction: 'none' },
          },
        }}
        classes={{ container: classes.backdrop }}
      >
        <DialogTitle disableTypography className={classes.root}>
          <div className={classes.headerContainer}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item style={{ width: '36px', height: '36px' }}>
                <div />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="inherit">
                  {crewToEdit ? i18n.t('Edit operating crew') : i18n.t('Add operating crew')}
                  {crewDetails.crewNumber === 2 ? ' 2' : ''}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label={i18n.t('close')}
                  onClick={openDiscardEditsModel}
                  id="close-crew-modal"
                >
                  <Icons.XFeather strokeWidth={1} color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            <div className={`${classes.rightButtonContainer} `} />
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <EditCrewDetailsPanel
            crewDetails={crewDetails}
            onCrewEmployeeChange={onCrewEmployeeChange}
            onCrewEmployeeRoleChange={onCrewEmployeeRoleChange}
            onCrewDetailsChange={onCrewDetailsChange}
            setValidationError={setValidationError}
            validationIndex={validationIndex}
            employeesList={employeesList}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Grid container justify="space-between">
            <Grid container item xs={8}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={openDiscardEditsModel}
                  data-cy="cancelComment"
                  noMinHeight
                  id="cancel-crew-modal"
                >
                  {i18n.t('Cancel')}
                </Button>
              </Grid>
              {crewToEdit?.index === 1 && (
                <Grid item>
                  <Button
                    variant="text"
                    color="error"
                    noMinHeight
                    isLoading={saving}
                    onClick={onDelete}
                    id="delete-crew-modal"
                  >
                    {i18n.t('Delete crew')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                data-cy="onSave"
                noMinHeight
                isLoading={saving}
                onClick={onSave}
                disabled={!validateCanSave}
                id="save-crew-modal"
              >
                {i18n.t('Save')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </SidePanel>
      <DiscardDraftModal
        open={discardOpen}
        titleText={deleteMode.current ? i18n.t('Delete operating crew 2') : undefined}
        cancelText={
          deleteMode
            ? i18n.t('Deleting this operating crew will lose any information that has been added')
            : undefined
        }
        discardDraftButtonText={deleteMode.current ? i18n.t('Delete operating crew 2') : undefined}
        onCancel={() => {
          setDiscardOpen(false);
          deleteMode.current = false;
          setUndo(false);
        }}
        onDiscard={async () => {
          setDiscardOpen(false);
          if (deleteMode.current) {
            try {
              await deleteBorerShiftCrew(originalCrewDetails);
              deleteMode.current = false;
              setModifyCrewSidePanelOpen(false);
              errorNotification(i18n.t('Operating crew 2 deleted'));
              setTimeout(() => {
                triggerRefresh();
              }, 100);
            } catch (err) {
              console.error('🚀 ~ file: ModifyCrewSidePanel.tsx ~ line 404 ~ err', err);
              errorNotification('Error deleting crew');
            }
          } else {
            setModifyCrewSidePanelOpen(false);
            errorNotification(
              crewDetails.crewNumber === 2
                ? i18n.t('Operating crew 2 draft discarded')
                : i18n.t('Operating crew draft discarded'),
              {
                action: key => (
                  <Button
                    noMinHeight
                    color="primary"
                    variant="text"
                    onClick={() => {
                      setUndo(true);
                      closeSnackbar(key);
                      setModifyCrewSidePanelOpen(true);
                    }}
                    id="undo-discard-crew-modal"
                  >
                    {i18n.t('Undo')}
                  </Button>
                ),
              },
            );
          }
        }}
      />
    </>
  );
};

export default observer(ModifyCrewSidePanel);
