import { useFlags } from '@nutrien/minesight-utility-module';
import { useEffect } from 'react';

import { FLAG_CACHE_KEY } from '../getDefaultFlagValues';

// Utility hook to cache flag values in local storage
const useCacheFlagValues = () => {
  const { flags } = useFlags();

  useEffect(() => {
    if (flags) localStorage.setItem(FLAG_CACHE_KEY, JSON.stringify(flags));
  }, [flags]);
};

export default useCacheFlagValues;
