/* eslint-disable import/no-cycle */

import type {
  MaybePromise,
  RxCollection,
  RxDatabase,
  RxGraphQLReplicationPullQueryBuilder,
  RxGraphQLReplicationPushQueryBuilder,
  SyncOptionsGraphQL,
} from 'rxdb';
import { WithDeleted } from 'rxdb';
import { replicateGraphQL, RxGraphQLReplicationState } from 'rxdb/plugins/replication-graphql';

import { apiConfig } from '../config/api.config';
import { BorerDatabaseCollections } from '../models/BorerDatabaseCollections';
import { SYNC_LIMIT_50, SYNC_LIMIT_HIGH, SYNC_LIMIT_LOW } from '../utilities/constants';
import { advancesPullQueryBuilder, advancesPushQueryBuilder } from './Advance/queryBuilder';
import { assignmentPullQueryBuilder } from './Assignment/queryBuilder';
import { assignmentEmployeePullQueryBuilder } from './AssignmentEmployee/queryBuilder';
import { assignmentRolePullQueryBuilder } from './AssignmentRole/queryBuilder';
import { blocksPullQueryBuilder } from './Blocks/queryBuilder';
import {
  borerActivityPullQueryBuilder,
  borerActivityPushModifer,
  borerActivityPushQueryBuilder,
} from './BorerActivity/queryBuilder';
import { borerActivityTypePullQueryBuilder } from './BorerActivityType/queryBuilder';
import {
  borerOperatorChangePushModifier,
  borerOperatorChangePushQueryBuilder,
} from './BorerOperatorChangeFeed/queryBuilder';
import { handleOperatorStateFeedEvents } from './BorerOperatorStateFeed/handleOperatorStateFeedEvents';
import {
  borerOperatorStatePullModifier,
  borerOperatorStatePullQueryBuilder,
} from './BorerOperatorStateFeed/queryBuilder';
import { borerShiftPullQueryBuilder } from './BorerShift/queryBuilder';
import {
  borerShiftActivityEmployeePullQueryBuilder,
  borerShiftActivityEmployeePushQueryBuilder,
} from './BorerShiftActivityEmployees/queryBuilder';
import {
  borerShiftCommentPullQueryBuilder,
  borerShiftCommentPushQueryBuilder,
} from './BorerShiftComment/queryBuilder';
import { borerShiftCommentTypePullQueryBuilder } from './BorerShiftCommentType/queryBuilder';
import {
  borerShiftCrewPullModifier,
  borerShiftCrewPullQueryBuilder,
  borerShiftCrewPushQueryBuilder,
} from './BorerShiftCrew/queryBuilder';
import {
  borerShiftCrewMemberPullQueryBuilder,
  borerShiftCrewMemberPushModifier,
  borerShiftCrewMemberPushQueryBuilder,
} from './BorerShiftCrewMember/queryBuilder';
import { BorerShiftCrewMemberRolePullQueryBuilder } from './BorerShiftCrewMemberRole/queryBuilder';
import {
  borerShiftInfoPullQueryBuilder,
  borerShiftInfoPushQueryBuilder,
} from './BorerShiftInfo/queryBuilder';
import {
  borerShiftSignaturePullQueryBuilder,
  borerShiftSignaturePushQueryBuilder,
  SIGNATURE_BATCH_SIZE,
  SIGNATURE_SYNC_INTERVAL,
} from './BorerShiftSignature/queryBuilder';
import { borerShiftTargetsPullQueryBuilder } from './BorerShiftTarget/queryBuilder';
import { borerStateTypeCategoryPullQueryBuilder } from './BorerStateTypeCategory/queryBuilder';
import { borerStateTypePullQueryBuilder } from './BorerStateTypeFeed/queryBuilder';
import { LiveInterval } from './constants';
import { crewPullQueryBuilder } from './Crew/queryBuilder';
import { cuttingMethodPullQueryBuilder } from './CuttingMethod/queryBuilder';
import {
  cuttingPermitSupervisorCommentPullQueryBuilder,
  cuttingPermitSupervisorCommentPushModifier,
  cuttingPermitSupervisorCommentPushQueryBuilder,
} from './CuttingPermitSupervisorComment/queryBuilder';
import { cuttingTypePullQueryBuilder } from './CuttingType/queryBuilder';
import { dailySafetyTopicsPullQueryBuilder } from './DailySafetyTopic/queryBuilder';
import { delayActivityTypeCategoryPullQueryBuilder } from './DelayActivityTypeCategory/queryBuilder';
import { departmentPullQueryBuilder } from './Department/queryBuilder';
import {
  documentUploadPullModifier,
  documentUploadPullQueryBuilder,
} from './Documents/queryBuilder';
import { documentTypePullQueryBuilder } from './DocumentTypes/queryBuilder';
import { employeesPullQueryBuilder } from './Employees/queryBuilder';
import { equipmentPullQueryBuilder } from './Equipment/queryBuilder';
import {
  equipmentDeficiencyPullQueryBuilder,
  equipmentDeficiencyPushQueryBuilder,
} from './EquipmentDeficiency/queryBuilder';
import {
  equipmentDeficiencyAttachmentPullQueryBuilder,
  equipmentDeficiencyAttachmentPushQueryBuilder,
} from './EquipmentDeficiencyAttachments/queryBuilder';
import {
  equipmentDeficiencyLogPullQueryBuilder,
  equipmentDeficiencyLogPushQueryBuilder,
} from './EquipmentDeficiencyLog/queryBuilder';
import { equipmentStatusPullQueryBuilder } from './EquipmentStatus/queryBuilder';
import { equipmentTypePullQueryBuilder } from './EquipmentType/queryBuilder';
import {
  groundControlSetPushQueryBuilder,
  groundControlSetsPullModifier,
  groundControlSetsPullQueryBuilder,
  groundControlSetsPushModifier,
} from './GroundControlSet/queryBuilder';
import { groundControlTypesPullQueryBuilder } from './GroundControlTypes/queryBuilder';
import {
  groundHazardPullModifier,
  groundHazardPushQueryBuilder,
  groundHazardsPullQueryBuilder,
} from './GroundHazard/queryBuilder';
import {
  groundHazardAttachmentPushQueryBuilder,
  groundHazardAttachmentsPullQueryBuilder,
} from './GroundHazardAttachment/queryBuilder';
import {
  hazardLogPushQueryBuilder,
  hazardLogsPullQueryBuilder,
} from './GroundHazardLog/queryBuilder';
import { handleReplicationErrors } from './handleReplicationErrors';
import { groundHazardConditionTypePullQueryBuilder } from './HazardConditionType/queryBuilder';
import { hazardSeverityPullQueryBuilder } from './HazardSeverities/queryBuilder';
import { inspectionCategoriesPullQueryBuilder } from './InspectionCategories/queryBuilder';
import { inspectionOptionsPullQueryBuilder } from './InspectionOptions/queryBuilder';
import {
  inspectionResultPullQueryBuilder,
  inspectionResultPushModifier,
  inspectionResultPushQueryBuilder,
} from './InspectionResults/queryBuilder';
import { inspectionPullQueryBuilder } from './Inspections/queryBuilder';
import { inspectionSeverityPullQueryBuilder } from './InspectionSeverityFeed/queryBuilder';
import { locationPushQueryBuilder, locationsPullQueryBuilder } from './Locations/queryBuilder';
import { miningCutPullQueryBuilder } from './MiningCut/queryBuilder';
import { miningPatternPullQueryBuilder } from './MiningPattern/queryBuilder';
import {
  PANEL_DRAWING_BATCH_SIZE,
  PANEL_DRAWING_SYNC_INTERVAL,
  panelDrawingPullQueryBuilder,
  panelDrawingPushQueryBuilder,
} from './PanelDrawing/queryBuilder';
import {
  panelDrawingCommentPullQueryBuilder,
  panelDrawingCommentPushQueryBuilder,
} from './PanelDrawingComment/queryBuilder';
import { panelLogPushQueryBuilder, panelLogsPullQueryBuilder } from './PanelLog/queryBuilder';
import { panelsPullQueryBuilder } from './Panels/queryBuilder';
import { passesPullQueryBuilder } from './Passes/queryBuilder';
import { positionPullQueryBuilder } from './Position/queryBuilder';
import {
  predictionPullModifier,
  predictionPullQueryBuilder,
  predictionPushQueryBuilder,
} from './Prediction/queryBuilder';
import { productionPullQueryBuilder, productionPushQueryBuilder } from './Productions/queryBuilder';
import { roomsPullQueryBuilder } from './Rooms/queryBuilder';
import RxdbCollectionName from './rxdbCollectionName';
import RxdbManager from './RxdbManager';
import { sequencesPullQueryBuilder } from './Sequences/queryBuilder';
import { serviceStatusPullQueryBuilder } from './ServiceStatus/queryBuilder';
import { shiftPullQueryBuilder } from './Shifts/queryBuilder';
import {
  signaturePullQueryBuilder,
  signaturePushQueryBuilder,
} from './Signature/rxdbSignatureDefinition';
import { sitePullQueryBuilder } from './Site/queryBuilder';
import { supplyPullQueryBuilder, supplyPushQueryBuilder } from './Supply/queryBuilder';
import { supplyItemPullQueryBuilder } from './SupplyItem/queryBuilder';
import { surveyPointsPullQueryBuilder } from './SurveyPoints/queryBuilder';
import { targetBorerRunningTimesPullQueryBuilder } from './TargetBorerRunningTimes/queryBuilder';
import { CheckpointType } from './types';
import { workOrdersPullQueryBuilder } from './WorkOrders/queryBuilder';

export const DEBUG = localStorage.getItem('DEBUG') === 'true';
if (DEBUG) console.log('RxDB Debugging Enabled...');

const syncGraphQLFor = (
  idToken: string,
  rxdb: RxDatabase<BorerDatabaseCollections, any, any>,
  collectionName: RxdbCollectionName,
  pullQueryBuilder?: RxGraphQLReplicationPullQueryBuilder<CheckpointType> | null,
  pullModifier?: null | ((docData: any) => MaybePromise<WithDeleted<any>>),
  pushQueryBuilder?: RxGraphQLReplicationPushQueryBuilder | null,
  pushModifier?: null | ((docData: WithDeleted<any>) => MaybePromise<any>),
  // Used when we absolutely need to update a document that was already deleted
  // See comments on https://github.com/Nutrien/minesight-borer-app/pull/514/
  bypassDeletedFlag = false,
  live = true,
  syncInterval = LiveInterval.SECONDS_30,
  pullBatchSize = SYNC_LIMIT_LOW,
  overrideGraphQLURL?: string,
  retryTime = 3000,
) => {
  const collection: RxCollection = rxdb.collections[collectionName];

  const syncConfig: SyncOptionsGraphQL<any, CheckpointType> = {
    collection,
    url: { http: `${overrideGraphQLURL || apiConfig.graphUrl}?collection=${collectionName}` },
    headers: { Authorization: idToken },
    deletedField: bypassDeletedFlag ? 'notUsed' : 'isDeleted',
    live,
    retryTime, // on failed mutation retry after 5 seconds, this reduces the number of foreign key constraint errors,
  };

  if (pullQueryBuilder) {
    syncConfig.pull = {
      queryBuilder: pullQueryBuilder,
      modifier: pullModifier || undefined,
      batchSize: pullBatchSize,
      responseModifier: (plainResponse, origin, requestCheckpoint) => {
        const lastDoc = plainResponse.length === 0 ? null : plainResponse[plainResponse.length - 1];

        const returnData = {
          documents: plainResponse,
          checkpoint:
            plainResponse.length === 0
              ? requestCheckpoint
              : {
                  id: lastDoc.id,
                  updatedAt: lastDoc.updatedAt,
                },
        };

        return returnData;
      },
    };
  }

  if (pushQueryBuilder) {
    syncConfig.push = {
      queryBuilder: pushQueryBuilder,
      batchSize: 1,
      modifier: pushModifier || undefined,
      responseModifier: async data => {
        if (collectionName === RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED) {
          const borerStateId = data.borerStateId;
          // Temp state was not coded, mark as failed
          if (data.status !== 'processed' && borerStateId) {
            const db = RxdbManager.instance?.db;
            const borerOperatorChangeCollection =
              db?.collections[RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED];
            const tempStateDocsForBorerStateId = await borerOperatorChangeCollection
              ?.find({
                selector: {
                  borerStateId,
                },
              })
              .exec();
            tempStateDocsForBorerStateId?.forEach(doc =>
              doc.incrementalPatch({ failedSync: true }),
            );
          }
        }
        // we have to return an empty array here since we don't recieve the set of conflicts from our BE
        return [];
      },
    };
  }

  const replicationState = replicateGraphQL(syncConfig);

  return {
    replicationState,
    reSyncInterval: setInterval(() => replicationState.reSync(), syncInterval),
  };
};

export default async (
  idToken: string,
  db: RxDatabase<BorerDatabaseCollections, any, any>,
  live = true,
) => {
  const syncStates: RxGraphQLReplicationState<any, any>[] = [];
  const reSyncTimeouts: NodeJS.Timeout[] = [];

  const locationsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.LOCATIONS,
    locationsPullQueryBuilder,
    undefined,
    locationPushQueryBuilder,
    null,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_HIGH,
  );
  if (locationsState) {
    syncStates.push(locationsState.replicationState);
    reSyncTimeouts.push(locationsState.reSyncInterval);
  }

  const shiftState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SHIFT,
    shiftPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (shiftState) {
    syncStates.push(shiftState.replicationState);
    reSyncTimeouts.push(shiftState.reSyncInterval);
  }

  const borerShiftState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT,
    borerShiftPullQueryBuilder,
    undefined,
    undefined,
    null,
    false,
    live,
  );
  if (borerShiftState) {
    syncStates.push(borerShiftState.replicationState);
    reSyncTimeouts.push(borerShiftState.reSyncInterval);
  }

  const employeesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EMPLOYEES,
    employeesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (employeesState) {
    syncStates.push(employeesState.replicationState);
    reSyncTimeouts.push(employeesState.reSyncInterval);
  }

  const siteState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SITES,
    sitePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (siteState) {
    syncStates.push(siteState.replicationState);
    reSyncTimeouts.push(siteState.reSyncInterval);
  }

  const blocksState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BLOCKS,
    blocksPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (blocksState) {
    syncStates.push(blocksState.replicationState);
    reSyncTimeouts.push(blocksState.reSyncInterval);
  }

  const panelsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANELS,
    panelsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (panelsState) {
    syncStates.push(panelsState.replicationState);
    reSyncTimeouts.push(panelsState.reSyncInterval);
  }

  const roomsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ROOMS,
    roomsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (roomsState) {
    syncStates.push(roomsState.replicationState);
    reSyncTimeouts.push(roomsState.reSyncInterval);
  }

  const surveyPointsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SURVEY_POINTS,
    surveyPointsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_HIGH,
  );
  if (surveyPointsState) {
    syncStates.push(surveyPointsState.replicationState);
    reSyncTimeouts.push(surveyPointsState.reSyncInterval);
  }

  const sequencesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SEQUENCES,
    sequencesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (sequencesState) {
    syncStates.push(sequencesState.replicationState);
    reSyncTimeouts.push(sequencesState.reSyncInterval);
  }

  const passState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PASSES,
    passesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (passState) {
    syncStates.push(passState.replicationState);
    reSyncTimeouts.push(passState.reSyncInterval);
  }

  const hazardLogsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.HAZARD_LOGS,
    hazardLogsPullQueryBuilder,
    undefined,
    hazardLogPushQueryBuilder,
    null, //createHazardLogPushModifier,
    false,
    live,
    undefined,
    undefined,
    undefined,
    7500,
  );
  if (hazardLogsState) {
    syncStates.push(hazardLogsState.replicationState);
    reSyncTimeouts.push(hazardLogsState.reSyncInterval);
  }

  const groundHazardsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARDS,
    groundHazardsPullQueryBuilder,
    groundHazardPullModifier,
    groundHazardPushQueryBuilder,
    null,
    // groundHazardPushModifier,
    false,
    live,
    undefined,
    undefined,
    undefined,
    3000,
  );
  if (groundHazardsState) {
    syncStates.push(groundHazardsState.replicationState);
    reSyncTimeouts.push(groundHazardsState.reSyncInterval);
  }

  const groundHazardConditionTypesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARD_CONDITION_TYPES,
    groundHazardConditionTypePullQueryBuilder,
    undefined,
    undefined,
    undefined,
    false,
    live,
  );
  if (groundHazardConditionTypesState) {
    syncStates.push(groundHazardConditionTypesState.replicationState);
    reSyncTimeouts.push(groundHazardConditionTypesState.reSyncInterval);
  }

  const groundHazardAttachmentsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_HAZARDS_ATTACHMENTS,
    groundHazardAttachmentsPullQueryBuilder,
    undefined,
    groundHazardAttachmentPushQueryBuilder,
    null, //groundHazardAttachmentPushModifier,
    false,
    live,
  );
  if (groundHazardAttachmentsState) {
    syncStates.push(groundHazardAttachmentsState.replicationState);
    reSyncTimeouts.push(groundHazardAttachmentsState.reSyncInterval);
  }

  const groundControlTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_CONTROL_TYPES,
    groundControlTypesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (groundControlTypeState) {
    syncStates.push(groundControlTypeState.replicationState);
    reSyncTimeouts.push(groundControlTypeState.reSyncInterval);
  }

  const groundControlSetsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.GROUND_CONTROL_SETS,
    groundControlSetsPullQueryBuilder,
    groundControlSetsPullModifier,
    groundControlSetPushQueryBuilder,
    groundControlSetsPushModifier,
    false,
    live,
    undefined,
    SYNC_LIMIT_HIGH,
    undefined,
    3000,
  );
  if (groundControlSetsState) {
    syncStates.push(groundControlSetsState.replicationState);
    reSyncTimeouts.push(groundControlSetsState.reSyncInterval);
  }

  const signatureState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SIGNATURES,
    signaturePullQueryBuilder,
    undefined,
    signaturePushQueryBuilder,
    null,
    false,
    live,
    SIGNATURE_SYNC_INTERVAL,
    SIGNATURE_BATCH_SIZE,
    undefined,
    3000,
  );

  if (signatureState) {
    syncStates.push(signatureState.replicationState);
    reSyncTimeouts.push(signatureState.reSyncInterval);
  }

  const borerShiftSignatureState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_SIGNATURE,
    borerShiftSignaturePullQueryBuilder,
    undefined,
    borerShiftSignaturePushQueryBuilder,
    null,
    false,
    live,
    undefined,
    undefined,
    undefined,
    7500,
  );
  if (borerShiftSignatureState) {
    syncStates.push(borerShiftSignatureState.replicationState);
    reSyncTimeouts.push(borerShiftSignatureState.reSyncInterval);
  }

  const inspectionsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTIONS,
    inspectionPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (inspectionsState) {
    syncStates.push(inspectionsState.replicationState);
    reSyncTimeouts.push(inspectionsState.reSyncInterval);
  }

  const inspectionCategoriesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_CATEGORIES,
    inspectionCategoriesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (inspectionCategoriesState) {
    syncStates.push(inspectionCategoriesState.replicationState);
    reSyncTimeouts.push(inspectionCategoriesState.reSyncInterval);
  }

  const inspectionOptionsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_OPTIONS,
    inspectionOptionsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (inspectionOptionsState) {
    syncStates.push(inspectionOptionsState.replicationState);
    reSyncTimeouts.push(inspectionOptionsState.reSyncInterval);
  }

  const inspectionSeverityState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_SEVERITY,
    inspectionSeverityPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (inspectionSeverityState) {
    syncStates.push(inspectionSeverityState.replicationState);
    reSyncTimeouts.push(inspectionSeverityState.reSyncInterval);
  }

  const hazardSeveritiesState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.HAZARD_SEVERITIES,
    hazardSeverityPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (hazardSeveritiesState) {
    syncStates.push(hazardSeveritiesState.replicationState);
    reSyncTimeouts.push(hazardSeveritiesState.reSyncInterval);
  }

  const inspectionResultsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.INSPECTION_RESULTS,
    inspectionResultPullQueryBuilder,
    undefined,
    inspectionResultPushQueryBuilder,
    inspectionResultPushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_50,
    undefined,
    7500,
  );
  if (inspectionResultsState) {
    syncStates.push(inspectionResultsState.replicationState);
    reSyncTimeouts.push(inspectionResultsState.reSyncInterval);
  }

  const cuttingPermitSupervisorCommentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.CUTTING_PERMIT_SUPERVISOR_COMMENT,
    cuttingPermitSupervisorCommentPullQueryBuilder,
    undefined,
    cuttingPermitSupervisorCommentPushQueryBuilder,
    cuttingPermitSupervisorCommentPushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_50,
    undefined,
    7500,
  );
  if (cuttingPermitSupervisorCommentState) {
    syncStates.push(cuttingPermitSupervisorCommentState.replicationState);
    reSyncTimeouts.push(cuttingPermitSupervisorCommentState.reSyncInterval);
  }

  const equipmentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_TYPE,
    equipmentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (equipmentTypeState) {
    syncStates.push(equipmentTypeState.replicationState);
    reSyncTimeouts.push(equipmentTypeState.reSyncInterval);
  }

  const equipmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT,
    equipmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (equipmentState) {
    syncStates.push(equipmentState.replicationState);
    reSyncTimeouts.push(equipmentState.reSyncInterval);
  }

  const equipmentStatusState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_STATUS,
    equipmentStatusPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (equipmentStatusState) {
    syncStates.push(equipmentStatusState.replicationState);
    reSyncTimeouts.push(equipmentStatusState.reSyncInterval);
  }

  const equipmentDeficiencyState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY,
    equipmentDeficiencyPullQueryBuilder,
    undefined,
    equipmentDeficiencyPushQueryBuilder,
    null,
    false,
    live,
  );
  if (equipmentDeficiencyState) {
    syncStates.push(equipmentDeficiencyState.replicationState);
    reSyncTimeouts.push(equipmentDeficiencyState.reSyncInterval);
  }

  const equipmentDeficiencyLogState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_LOG,
    equipmentDeficiencyLogPullQueryBuilder,
    undefined,
    equipmentDeficiencyLogPushQueryBuilder,
    null,
    false,
    live,
  );
  if (equipmentDeficiencyLogState) {
    syncStates.push(equipmentDeficiencyLogState.replicationState);
    reSyncTimeouts.push(equipmentDeficiencyLogState.reSyncInterval);
  }

  const equipmentDeficiencyAttachmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.EQUIPMENT_DEFICIENCY_ATTACHMENT,
    equipmentDeficiencyAttachmentPullQueryBuilder,
    undefined,
    equipmentDeficiencyAttachmentPushQueryBuilder,
    null,
    false,
    live,
  );
  if (equipmentDeficiencyAttachmentState) {
    syncStates.push(equipmentDeficiencyAttachmentState.replicationState);
    reSyncTimeouts.push(equipmentDeficiencyAttachmentState.reSyncInterval);
  }

  const borerActivityState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_ACTIVITY,
    borerActivityPullQueryBuilder,
    undefined,
    borerActivityPushQueryBuilder,
    borerActivityPushModifer,
    false,
    live,
  );
  if (borerActivityState) {
    syncStates.push(borerActivityState.replicationState);
    reSyncTimeouts.push(borerActivityState.reSyncInterval);
  }

  const borerActivityTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_ACTIVITY_TYPE,
    borerActivityTypePullQueryBuilder,
    undefined,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (borerActivityTypeState) {
    syncStates.push(borerActivityTypeState.replicationState);
    reSyncTimeouts.push(borerActivityTypeState.reSyncInterval);
  }

  const delayActivityTypeCategoryState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DELAY_ACTIVITY_TYPE_CATEGORY,
    delayActivityTypeCategoryPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (delayActivityTypeCategoryState) {
    syncStates.push(delayActivityTypeCategoryState.replicationState);
    reSyncTimeouts.push(delayActivityTypeCategoryState.reSyncInterval);
  }

  const workOrdersState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.WORK_ORDERS,
    workOrdersPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (workOrdersState) {
    syncStates.push(workOrdersState.replicationState);
    reSyncTimeouts.push(workOrdersState.reSyncInterval);
  }

  const borerShiftActivityEmployeeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_ACTIVITY_EMPLOYEES,
    borerShiftActivityEmployeePullQueryBuilder,
    undefined,
    borerShiftActivityEmployeePushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftActivityEmployeeState) {
    syncStates.push(borerShiftActivityEmployeeState.replicationState);
    reSyncTimeouts.push(borerShiftActivityEmployeeState.reSyncInterval);
  }

  const borerShiftCrewState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_CREW,
    borerShiftCrewPullQueryBuilder,
    borerShiftCrewPullModifier,
    borerShiftCrewPushQueryBuilder,
    null,
    true,
    live,
  );
  if (borerShiftCrewState) {
    syncStates.push(borerShiftCrewState.replicationState);
    reSyncTimeouts.push(borerShiftCrewState.reSyncInterval);
  }

  const borerShiftCrewMemberState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER,
    borerShiftCrewMemberPullQueryBuilder,
    undefined,
    borerShiftCrewMemberPushQueryBuilder,
    borerShiftCrewMemberPushModifier,
    false,
    live,
  );
  if (borerShiftCrewMemberState) {
    syncStates.push(borerShiftCrewMemberState.replicationState);
    reSyncTimeouts.push(borerShiftCrewMemberState.reSyncInterval);
  }

  const borerShiftAdvanceState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_ADVANCE,
    advancesPullQueryBuilder,
    undefined,
    advancesPushQueryBuilder,
    null,
    false,
    live,
    undefined,
    undefined,
    undefined,
    3000,
  );
  if (borerShiftAdvanceState) {
    syncStates.push(borerShiftAdvanceState.replicationState);
    reSyncTimeouts.push(borerShiftAdvanceState.reSyncInterval);
  }

  const borerShiftProductionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_PRODUCTION,
    productionPullQueryBuilder,
    null,
    productionPushQueryBuilder,
    null,
    false,
    live,
    undefined,
    undefined,
    undefined,
    7500,
  );
  if (borerShiftProductionState) {
    syncStates.push(borerShiftProductionState.replicationState);
    reSyncTimeouts.push(borerShiftProductionState.reSyncInterval);
  }

  const borerShiftPredictionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_PREDICTION,
    predictionPullQueryBuilder,
    predictionPullModifier,
    predictionPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftPredictionState) {
    syncStates.push(borerShiftPredictionState.replicationState);
    reSyncTimeouts.push(borerShiftPredictionState.reSyncInterval);
  }

  const miningCutState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.MINING_CUTS,
    miningCutPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (miningCutState) {
    syncStates.push(miningCutState.replicationState);
    reSyncTimeouts.push(miningCutState.reSyncInterval);
  }

  const assignmentRoleState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT_ROLE,
    assignmentRolePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (assignmentRoleState) {
    syncStates.push(assignmentRoleState.replicationState);
    reSyncTimeouts.push(assignmentRoleState.reSyncInterval);
  }

  const assignmentEmployeeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT_EMPLOYEE,
    assignmentEmployeePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (assignmentEmployeeState) {
    syncStates.push(assignmentEmployeeState.replicationState);
    reSyncTimeouts.push(assignmentEmployeeState.reSyncInterval);
  }

  const assignmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.ASSIGNMENT,
    assignmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (assignmentState) {
    syncStates.push(assignmentState.replicationState);
    reSyncTimeouts.push(assignmentState.reSyncInterval);
  }

  const panelDrawingState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_DRAWINGS,
    panelDrawingPullQueryBuilder,
    undefined,
    panelDrawingPushQueryBuilder,
    null,
    false,
    live,
    PANEL_DRAWING_SYNC_INTERVAL,
    PANEL_DRAWING_BATCH_SIZE,
  );
  if (panelDrawingState) {
    syncStates.push(panelDrawingState.replicationState);
    reSyncTimeouts.push(panelDrawingState.reSyncInterval);
  }

  const panelDrawingCommentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_DRAWING_COMMENTS,
    panelDrawingCommentPullQueryBuilder,
    undefined,
    panelDrawingCommentPushQueryBuilder,
    null,
    false,
    live,
  );
  if (panelDrawingCommentState) {
    syncStates.push(panelDrawingCommentState.replicationState);
    reSyncTimeouts.push(panelDrawingCommentState.reSyncInterval);
  }

  const dailySafetyTopicsState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DAILY_SAFETY_TOPIC,
    dailySafetyTopicsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (dailySafetyTopicsState) {
    syncStates.push(dailySafetyTopicsState.replicationState);
    reSyncTimeouts.push(dailySafetyTopicsState.reSyncInterval);
  }

  const borerShiftInfoState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_INFO,
    borerShiftInfoPullQueryBuilder,
    undefined,
    borerShiftInfoPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftInfoState) {
    syncStates.push(borerShiftInfoState.replicationState);
    reSyncTimeouts.push(borerShiftInfoState.reSyncInterval);
  }

  const documentUploadState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DOCUMENT_UPLOAD,
    documentUploadPullQueryBuilder,
    documentUploadPullModifier,
    null,
    null,
    false,
    live,
  );
  if (documentUploadState) {
    syncStates.push(documentUploadState.replicationState);
    reSyncTimeouts.push(documentUploadState.reSyncInterval);
  }

  const documentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DOCUMENT_TYPES,
    documentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (documentTypeState) {
    syncStates.push(documentTypeState.replicationState);
    reSyncTimeouts.push(documentTypeState.reSyncInterval);
  }

  // Supply Subscription Spike
  const supplyState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SUPPLIES,
    supplyPullQueryBuilder,
    null,
    supplyPushQueryBuilder,
    null,
    false,
    live,
    LiveInterval.SECONDS_60,
  );
  if (supplyState) {
    syncStates.push(supplyState.replicationState);
    reSyncTimeouts.push(supplyState.reSyncInterval);
  }

  const supplyItemState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SUPPLY_ITEMS,
    supplyItemPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (supplyItemState) {
    syncStates.push(supplyItemState.replicationState);
    reSyncTimeouts.push(supplyItemState.reSyncInterval);
  }

  const serviceStatusState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.SERVICE_STATUS,
    serviceStatusPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (serviceStatusState) {
    syncStates.push(serviceStatusState.replicationState);
    reSyncTimeouts.push(serviceStatusState.reSyncInterval);
  }

  const targetBorerRunningTimeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.TARGET_BORER_RUNNING_TIMES,
    targetBorerRunningTimesPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (targetBorerRunningTimeState) {
    syncStates.push(targetBorerRunningTimeState.replicationState);
    reSyncTimeouts.push(targetBorerRunningTimeState.reSyncInterval);
  }

  const crewState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.CREWS,
    crewPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (crewState) {
    syncStates.push(crewState.replicationState);
    reSyncTimeouts.push(crewState.reSyncInterval);
  }

  const positionState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.POSITIONS,
    positionPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (positionState) {
    syncStates.push(positionState.replicationState);
    reSyncTimeouts.push(positionState.reSyncInterval);
  }

  const miningPatternState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.MINING_PATTERN,
    miningPatternPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (miningPatternState) {
    syncStates.push(miningPatternState.replicationState);
    reSyncTimeouts.push(miningPatternState.reSyncInterval);
  }

  const departmentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.DEPARTMENT,
    departmentPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (departmentState) {
    syncStates.push(departmentState.replicationState);
    reSyncTimeouts.push(departmentState.reSyncInterval);
  }

  const borerShiftCommentState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_COMMENT,
    borerShiftCommentPullQueryBuilder,
    null,
    borerShiftCommentPushQueryBuilder,
    null,
    false,
    live,
  );
  if (borerShiftCommentState) {
    syncStates.push(borerShiftCommentState.replicationState);
    reSyncTimeouts.push(borerShiftCommentState.reSyncInterval);
  }

  const borerShiftCommentTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_COMMENT_TYPE,
    borerShiftCommentTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
  );
  if (borerShiftCommentTypeState) {
    syncStates.push(borerShiftCommentTypeState.replicationState);
    reSyncTimeouts.push(borerShiftCommentTypeState.reSyncInterval);
  }

  const panelLogState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.PANEL_LOGS,
    panelLogsPullQueryBuilder,
    null,
    panelLogPushQueryBuilder,
    null,
    false,
    live,
  );
  if (panelLogState) {
    syncStates.push(panelLogState.replicationState);
    reSyncTimeouts.push(panelLogState.reSyncInterval);
  }

  const borerShiftTargetState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_TARGET,
    borerShiftTargetsPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (borerShiftTargetState) {
    syncStates.push(borerShiftTargetState.replicationState);
    reSyncTimeouts.push(borerShiftTargetState.reSyncInterval);
  }
  const BorerShiftCrewMemberRoleState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_SHIFT_CREW_MEMBER_ROLE,
    BorerShiftCrewMemberRolePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
  );
  if (BorerShiftCrewMemberRoleState) {
    syncStates.push(BorerShiftCrewMemberRoleState.replicationState);
    reSyncTimeouts.push(BorerShiftCrewMemberRoleState.reSyncInterval);
  }

  const borerOperatorStateFeedState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_OPERATOR_STATE_FEED,
    borerOperatorStatePullQueryBuilder,
    borerOperatorStatePullModifier,
    null,
    null,
    false,
    live,
    LiveInterval.SECONDS_15,
    SYNC_LIMIT_LOW,
  );
  if (borerOperatorStateFeedState) {
    syncStates.push(borerOperatorStateFeedState.replicationState);
    reSyncTimeouts.push(borerOperatorStateFeedState.reSyncInterval);
  }

  const borerStateTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_STATE_TYPE,
    borerStateTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_15,
    SYNC_LIMIT_LOW,
  );
  if (borerStateTypeState) {
    syncStates.push(borerStateTypeState.replicationState);
    reSyncTimeouts.push(borerStateTypeState.reSyncInterval);
  }

  const borerStateTypeCategoryState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_STATE_TYPE_CATEGORY,
    borerStateTypeCategoryPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_30,
    SYNC_LIMIT_LOW,
  );
  if (borerStateTypeCategoryState) {
    syncStates.push(borerStateTypeCategoryState.replicationState);
    reSyncTimeouts.push(borerStateTypeCategoryState.reSyncInterval);
  }

  const cuttingTypeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.CUTTING_TYPE,
    cuttingTypePullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_30,
    SYNC_LIMIT_LOW,
  );
  if (cuttingTypeState) {
    syncStates.push(cuttingTypeState.replicationState);
    reSyncTimeouts.push(cuttingTypeState.reSyncInterval);
  }

  const cuttingMethodState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.CUTTING_METHOD,
    cuttingMethodPullQueryBuilder,
    null,
    null,
    null,
    false,
    live,
    LiveInterval.MINUTES_30,
    SYNC_LIMIT_LOW,
  );
  if (cuttingMethodState) {
    syncStates.push(cuttingMethodState.replicationState);
    reSyncTimeouts.push(cuttingMethodState.reSyncInterval);
  }

  const borerOperatorChangeState = syncGraphQLFor(
    idToken,
    db,
    RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED,
    null,
    null,
    borerOperatorChangePushQueryBuilder,
    borerOperatorChangePushModifier,
    false,
    live,
    LiveInterval.SECONDS_60,
    SYNC_LIMIT_LOW,
  );
  if (borerOperatorChangeState) {
    syncStates.push(borerOperatorChangeState.replicationState);
    reSyncTimeouts.push(borerOperatorChangeState.reSyncInterval);
  }

  // Special case for handling new items on the borerOperatorStateFeed
  // We toggle showInSchedulerView as false for matching changeState items
  handleOperatorStateFeedEvents(
    borerOperatorStateFeedState.replicationState,
    borerOperatorChangeState.replicationState,
    db,
  );

  syncStates.forEach(state => handleReplicationErrors(state));

  return { db, syncStates, reSyncTimeouts };
};
