import { Storage } from 'aws-amplify';

import { DocumentUploadDocument } from '../../rxdb/Documents/queryBuilder';
import { XML_HEADER } from '../../utilities/constants';
import { fileToBase64 } from '../../utilities/fileConverter';
import { attachmentStore } from './CacheUpdateHandler';

const DEBUG = sessionStorage.getItem('DEBUG') === 'true';

export const defaultProtectionLevel = 'public';

export const getImage = (fileKey: string, protectionLevel = defaultProtectionLevel) => {
  return Storage.get(fileKey, { level: protectionLevel });
};

/**
 * Using a filename path in s3 pulls a copy of the file and stores it in the attachment store. Return true if successfully cached.
 *
 * @param {string} filename s3 filepath
 */

export const cacheDocumentInS3 = async (document: DocumentUploadDocument): Promise<boolean> => {
  if (!document.fileName) return false;
  if (DEBUG) console.log('Caching doc...', document.fileName);

  try {
    const preSignedUrl = await getImage(document.fileName);
    const response = await fetch(preSignedUrl as string);
    const blob = await response.blob();
    if (blob.type === XML_HEADER) {
      throw new Error(
        `File type is Application/XML, indicating the file is not in the S3 bucket. Retry download.
            File name: ${document.fileName}.`,
      );
    } else {
      const base64String = await fileToBase64(blob);
      attachmentStore.setItem(document.fileName, base64String);
      if (DEBUG) console.info('Doc cache successful ', document.fileName);

      return true;
    }
  } catch (error) {
    if (DEBUG) console.log('🚀 ~ file: CacheUpdateHandler.tsx:116 ~ error:', error);
    throw error;
  }
};
