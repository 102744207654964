// rxdb
import { addRxPlugin, createRxDatabase, RxDatabase } from 'rxdb';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import { RxDBMigrationPlugin } from 'rxdb/plugins/migration';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { getRxStorageMemory } from 'rxdb/plugins/storage-memory';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { getRxStorageIndexedDB } from 'rxdb-premium/plugins/storage-indexeddb';

import { isJestOrStorybook } from '@/test-helpers/isJestOrStorybook';
import { captureInSentryWithDetails } from '@/utilities/captureInSentryWithDetails';

import { BorerDatabaseCollections } from '../models/BorerDatabaseCollections';
import { advanceMigrationStrategies, advanceSchema } from './Advance/queryBuilder';
import { assignmentSchema } from './Assignment/queryBuilder';
import {
  assignmentEmployeeMigrationStrategies,
  assignmentEmployeeSchema,
} from './AssignmentEmployee/queryBuilder';
import { assignmentRoleSchema } from './AssignmentRole/queryBuilder';
import { blockSchema } from './Blocks/queryBuilder';
import {
  borerActivitySchema,
  borerShiftActivityMigrationStrategies,
} from './BorerActivity/queryBuilder';
import { borerActivityTypeSchema } from './BorerActivityType/queryBuilder';
import {
  borerOperatorChangeMigrationStrategies,
  borerOperatorChangeSchema,
} from './BorerOperatorChangeFeed/queryBuilder';
import {
  borerOperatorStateMigrationStrategy,
  borerOperatorStateSchema,
} from './BorerOperatorStateFeed/queryBuilder';
import { borerShiftMigrationStrategies, borerShiftSchema } from './BorerShift/queryBuilder';
import {
  borerShiftActivityEmployeeMigrationStrategies,
  borerShiftActivityEmployeeSchema,
} from './BorerShiftActivityEmployees/queryBuilder';
import {
  borerShiftCommentMigrationStrategies,
  borerShiftCommentSchema,
} from './BorerShiftComment/queryBuilder';
import {
  borerShiftCommentTypeMigrationStrategies,
  borerShiftCommentTypeSchema,
} from './BorerShiftCommentType/queryBuilder';
import {
  borerShiftCrewMigrationStrategies,
  borerShiftCrewSchema,
} from './BorerShiftCrew/queryBuilder';
import {
  borerShiftCrewMemberMigrationStrategies,
  borerShiftCrewMemberSchema,
} from './BorerShiftCrewMember/queryBuilder';
import {
  borerShiftCrewMemberRoleMigrationStrategies,
  BorerShiftCrewMemberRoleSchema,
} from './BorerShiftCrewMemberRole/queryBuilder';
import {
  borerShiftInfoMigrationStrategies,
  borerShiftInfoSchema,
} from './BorerShiftInfo/queryBuilder';
import {
  borerShiftSignatureMigrationStrategies,
  borerShiftSignatureSchema,
} from './BorerShiftSignature/queryBuilder';
import { borerShiftTargetSchema } from './BorerShiftTarget/queryBuilder';
import {
  borerStateTypeCategoryMigrationStrategies,
  borerStateTypeCategorySchema,
} from './BorerStateTypeCategory/queryBuilder';
import {
  borerStateTypeMigrationStrategies,
  borerStateTypeSchema,
} from './BorerStateTypeFeed/queryBuilder';
import { crewSchema } from './Crew/queryBuilder';
import { cuttingMethodSchema } from './CuttingMethod/queryBuilder';
import {
  cuttingPermitSupervisorCommentMigrationStrategies,
  cuttingPermitSupervisorCommentSchema,
} from './CuttingPermitSupervisorComment/queryBuilder';
import { cuttingTypeSchema } from './CuttingType/queryBuilder';
import { dailySafetyTopicSchema } from './DailySafetyTopic/queryBuilder';
import { removeRxDB15fromStorage } from './dbMigrations';
import { delayActivityTypeCategorySchema } from './DelayActivityTypeCategory/queryBuilder';
import { departmentSchema } from './Department/queryBuilder';
import { documentMigrationStrategies, documentUploadSchema } from './Documents/queryBuilder';
import { documentTypeSchema } from './DocumentTypes/queryBuilder';
import { employeeMigrationStrategies, employeesSchema } from './Employees/queryBuilder';
import { equipmentMigrationStrategies, equipmentSchema } from './Equipment/queryBuilder';
import { equipmentDeficiencySchema } from './EquipmentDeficiency/queryBuilder';
import { equipmentDeficiencyAttachmentSchema } from './EquipmentDeficiencyAttachments/queryBuilder';
import {
  equipmentDeficiencyLogSchema,
  equipmentDeficiencyLogStrategies,
} from './EquipmentDeficiencyLog/queryBuilder';
import { equipmentStatusSchema } from './EquipmentStatus/queryBuilder';
import { equipmentTypeSchema } from './EquipmentType/queryBuilder';
import { groundControlSetSchema } from './GroundControlSet/queryBuilder';
import { groundControlTypesSchema } from './GroundControlTypes/queryBuilder';
import { groundHazardMigrationStrategies, groundHazardSchema } from './GroundHazard/queryBuilder';
import {
  groundHazardAttachmentMigrationStrategies,
  groundHazardAttachmentSchema,
} from './GroundHazardAttachment/queryBuilder';
import {
  groundHazarLogdMigrationStrategies,
  hazardLogSchema,
} from './GroundHazardLog/queryBuilder';
import {
  groundHazardConditionTypeMigrationStrategies,
  groundHazardConditionTypeSchema,
} from './HazardConditionType/queryBuilder';
import { hazardSeveritySchema } from './HazardSeverities/queryBuilder';
import {
  inspectionCategoriesMigrationStrategies,
  inspectionCategoriesSchema,
} from './InspectionCategories/queryBuilder';
import {
  inspectionOptionsMigrationStrategies,
  inspectionOptionsSchema,
} from './InspectionOptions/queryBuilder';
import {
  inspectionResultMigrationStrategies,
  inspectionResultSchema,
} from './InspectionResults/queryBuilder';
import { inspectionSchema } from './Inspections/queryBuilder';
import { inspectionSeveritySchema } from './InspectionSeverityFeed/queryBuilder';
import { locationSchema } from './Locations/queryBuilder';
import { miningCutMigrationStrategies, miningCutSchema } from './MiningCut/queryBuilder';
import {
  miningPatternMigrationStrategies,
  miningPatternSchema,
} from './MiningPattern/queryBuilder';
import { panelDrawingSchema } from './PanelDrawing/queryBuilder';
import { panelDrawingCommentSchema } from './PanelDrawingComment/queryBuilder';
import { panelLogMigrationStrategies, panelLogSchema } from './PanelLog/queryBuilder';
import { panelMigrationStrategies, panelSchema } from './Panels/queryBuilder';
import { passesSchema } from './Passes/queryBuilder';
import { positionSchema } from './Position/queryBuilder';
import {
  borerShiftPredictionMigrationStrategies,
  predictionSchema,
} from './Prediction/queryBuilder';
import { productionMigrationStrategies, productionSchema } from './Productions/queryBuilder';
import { roomMigrationStrategies, roomSchema } from './Rooms/queryBuilder';
import RxdbCollectionName from './rxdbCollectionName';
import { sequencesSchema } from './Sequences/queryBuilder';
import { serviceStatusSchema } from './ServiceStatus/queryBuilder';
import { shiftSchema } from './Shifts/queryBuilder';
import { signatureMigrationStrategies, signatureSchema } from './Signature/rxdbSignatureDefinition';
import { siteMigrationStrategies, siteSchema } from './Site/queryBuilder';
import { supplyMigrationStrategies, supplySchema } from './Supply/queryBuilder';
import { supplyItemSchema } from './SupplyItem/queryBuilder';
import { surveyPointMigrationStrategies, surveyPointsSchema } from './SurveyPoints/queryBuilder';
import {
  targetBorerRunningTimeSchema,
  targetRunningTimesMigrationStrategies,
} from './TargetBorerRunningTimes/queryBuilder';
import { workOrdersMigrationStrategies, workOrdersSchema } from './WorkOrders/queryBuilder';

const isDev = import.meta.env.VITE_REACT_APP_ENV === 'dev';
const DEBUG_RXDB = localStorage.getItem('DEBUG_RXDB') === 'true';
if (isDev && !isJestOrStorybook()) addRxPlugin(RxDBDevModePlugin);

addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBMigrationPlugin);
addRxPlugin(RxDBUpdatePlugin);

export const rxdb14DatabaseName = 'borer_rxdb_14';
export const rxdb15DatabaseName = 'borer_rxdb_15';

const createRxDBv14 = (rxdbDbName: string) =>
  createRxDatabase<BorerDatabaseCollections>({
    name: rxdbDbName,
    storage: isJestOrStorybook() ? getRxStorageMemory() : getRxStorageIndexedDB(),
    ignoreDuplicate: true,
    multiInstance: false,
    cleanupPolicy: {
      minimumDeletedTime: 1000 * 60 * 60 * 24 * 14, // 14 Days,
    },
  });

const initializeDB = async () => {
  let db: RxDatabase<BorerDatabaseCollections> | undefined;
  try {
    // Create RxDB Instance
    if (DEBUG_RXDB) console.log('Creating Rxdb 15 db...');
    db = await createRxDBv14(rxdb14DatabaseName);
  } catch (error) {
    console.error('🚀 ~ file: initializeDB.ts ~ line 97 ~ initializeDB ~ error', error);
    captureInSentryWithDetails(error, {
      initializationError: true,
      rxDBError: true,
    });
    throw error;
  }

  window.db = db;

  // setup rxdb collections
  try {
    await db.addCollections({
      employees: {
        schema: employeesSchema,
        migrationStrategies: employeeMigrationStrategies,
      },
      mining_cuts: {
        schema: miningCutSchema,
        migrationStrategies: miningCutMigrationStrategies,
      },
      sites: {
        schema: siteSchema,
        migrationStrategies: siteMigrationStrategies,
      },
      passes: { schema: passesSchema },
      panels: {
        schema: panelSchema,
        migrationStrategies: panelMigrationStrategies,
      },
      rooms: {
        schema: roomSchema,
        migrationStrategies: roomMigrationStrategies,
      },
      sequences: { schema: sequencesSchema },
      survey_points: {
        schema: surveyPointsSchema,
        migrationStrategies: surveyPointMigrationStrategies,
      },
      ground_hazards: {
        schema: groundHazardSchema,
        migrationStrategies: groundHazardMigrationStrategies,
      },
      ground_hazard_condition_types: {
        schema: groundHazardConditionTypeSchema,
        migrationStrategies: groundHazardConditionTypeMigrationStrategies,
      },
      ground_hazard_attachments: {
        schema: groundHazardAttachmentSchema,
        migrationStrategies: groundHazardAttachmentMigrationStrategies,
      },
      locations: { schema: locationSchema },
      hazard_logs: {
        schema: hazardLogSchema,
        migrationStrategies: groundHazarLogdMigrationStrategies,
      },
      ground_control_types: { schema: groundControlTypesSchema },
      ground_control_sets: {
        schema: groundControlSetSchema,
      },
      signatures: {
        schema: signatureSchema,
        migrationStrategies: signatureMigrationStrategies,
      },
      borer_shift_signature: {
        schema: borerShiftSignatureSchema,
        migrationStrategies: borerShiftSignatureMigrationStrategies,
      },
      shift: { schema: shiftSchema },
      borer_shift: {
        schema: borerShiftSchema,
        migrationStrategies: borerShiftMigrationStrategies,
      },
      borer_shift_crew: {
        schema: borerShiftCrewSchema,
        migrationStrategies: borerShiftCrewMigrationStrategies,
      },
      borer_shift_crew_member: {
        schema: borerShiftCrewMemberSchema,
        migrationStrategies: borerShiftCrewMemberMigrationStrategies,
      },
      blocks: { schema: blockSchema },
      inspections: { schema: inspectionSchema },
      inspection_categories: {
        schema: inspectionCategoriesSchema,
        migrationStrategies: inspectionCategoriesMigrationStrategies,
      },
      inspection_options: {
        schema: inspectionOptionsSchema,
        migrationStrategies: inspectionOptionsMigrationStrategies,
      },
      hazard_severities: { schema: hazardSeveritySchema },
      inspection_results: {
        schema: inspectionResultSchema,
        migrationStrategies: inspectionResultMigrationStrategies,
      },
      cutting_permit_supervisor_comment: {
        schema: cuttingPermitSupervisorCommentSchema,
        migrationStrategies: cuttingPermitSupervisorCommentMigrationStrategies,
      },
      equipment: {
        schema: equipmentSchema,
        migrationStrategies: equipmentMigrationStrategies,
      },
      equipment_deficiency: {
        schema: equipmentDeficiencySchema,
      },
      equipment_type: { schema: equipmentTypeSchema },
      equipment_status: { schema: equipmentStatusSchema },
      equipment_deficiency_log: {
        schema: equipmentDeficiencyLogSchema,
        migrationStrategies: equipmentDeficiencyLogStrategies,
      },
      equipment_deficiency_attachment: {
        schema: equipmentDeficiencyAttachmentSchema,
      },
      borer_activity: {
        schema: borerActivitySchema,
        migrationStrategies: borerShiftActivityMigrationStrategies,
      },
      borer_activity_type: { schema: borerActivityTypeSchema },
      delay_activity_type_category: { schema: delayActivityTypeCategorySchema },
      work_orders: {
        schema: workOrdersSchema,
        migrationStrategies: workOrdersMigrationStrategies,
      },
      borer_shift_activity_employees: {
        schema: borerShiftActivityEmployeeSchema,
        migrationStrategies: borerShiftActivityEmployeeMigrationStrategies,
      },
      borer_shift_advance: {
        schema: advanceSchema,
        migrationStrategies: advanceMigrationStrategies,
      },
      borer_shift_production: {
        schema: productionSchema,
        migrationStrategies: productionMigrationStrategies,
      },
      borer_shift_prediction: {
        schema: predictionSchema,
        migrationStrategies: borerShiftPredictionMigrationStrategies,
      },
      panel_drawings: { schema: panelDrawingSchema },
      assignment_employee: {
        schema: assignmentEmployeeSchema,
        migrationStrategies: assignmentEmployeeMigrationStrategies,
      },
      assignment: { schema: assignmentSchema },
      assignment_role: { schema: assignmentRoleSchema },
      daily_safety_topic: { schema: dailySafetyTopicSchema },
      borer_shift_info: {
        schema: borerShiftInfoSchema,
        migrationStrategies: borerShiftInfoMigrationStrategies,
      },
      document_upload: {
        schema: documentUploadSchema,
        migrationStrategies: documentMigrationStrategies,
      },
      document_types: { schema: documentTypeSchema },
      supplies: {
        schema: supplySchema,
        migrationStrategies: supplyMigrationStrategies,
      },
      supply_items: {
        schema: supplyItemSchema,
      },
      service_status: { schema: serviceStatusSchema },
      panel_drawing_comments: {
        schema: panelDrawingCommentSchema,
      },
      target_borer_running_times: {
        schema: targetBorerRunningTimeSchema,
        migrationStrategies: targetRunningTimesMigrationStrategies,
      },
      crews: { schema: crewSchema },
      positions: { schema: positionSchema },
      mining_pattern: {
        schema: miningPatternSchema,
        migrationStrategies: miningPatternMigrationStrategies,
      },
      department: {
        schema: departmentSchema,
      },
      borer_shift_comment: {
        schema: borerShiftCommentSchema,
        migrationStrategies: borerShiftCommentMigrationStrategies,
      },
      borer_shift_comment_type: {
        schema: borerShiftCommentTypeSchema,
        migrationStrategies: borerShiftCommentTypeMigrationStrategies,
      },
      inspection_severity: {
        schema: inspectionSeveritySchema,
      },
      borer_operator_state_feed: {
        schema: borerOperatorStateSchema,
        migrationStrategies: borerOperatorStateMigrationStrategy,
      },
      borer_state_type: {
        schema: borerStateTypeSchema,
        migrationStrategies: borerStateTypeMigrationStrategies,
      },
      [RxdbCollectionName.BORER_OPERATOR_CHANGE_FEED]: {
        schema: borerOperatorChangeSchema,
        migrationStrategies: borerOperatorChangeMigrationStrategies,
      },
      [RxdbCollectionName.BORER_STATE_TYPE_CATEGORY]: {
        schema: borerStateTypeCategorySchema,
        migrationStrategies: borerStateTypeCategoryMigrationStrategies,
      },
      [RxdbCollectionName.PANEL_LOGS]: {
        schema: panelLogSchema,
        migrationStrategies: panelLogMigrationStrategies,
      },
      [RxdbCollectionName.CUTTING_TYPE]: {
        schema: cuttingTypeSchema,
      },
      [RxdbCollectionName.CUTTING_METHOD]: {
        schema: cuttingMethodSchema,
      },
      [RxdbCollectionName.BORER_SHIFT_TARGET]: {
        schema: borerShiftTargetSchema,
      },
      [RxdbCollectionName.BORER_SHIFT_CREW_MEMBER_ROLE]: {
        schema: BorerShiftCrewMemberRoleSchema,
        migrationStrategies: borerShiftCrewMemberRoleMigrationStrategies,
      },
    });
    if (DEBUG_RXDB) console.log('Added collections.');

    // Handle any pending database migrations - Disable for now while Rxdb issue is fixed
    // https://github.com/pubkey/rxdb/issues/5565
    // if (!isJestOrStorybook()) await migrateDBsAsNeeded(db);

    // Remove v15 DB if it exists (during downgrade from 15 -> 14)
    await removeRxDB15fromStorage();
  } catch (error) {
    console.log('🚀 ~ file: initializeDB.ts ~ line 386 ~ initializeDB ~ error', error);
    captureInSentryWithDetails(error, {
      initializationError: true,
      rxDBError: true,
    });
    if (!isJestOrStorybook()) window.location.reload();
  }

  return { db };
};

/**
 * Refresh the token on the collection state object every 5 minutes
 *
 * @param {RxGraphQLReplicationState} state
 * @param {number} [checkIntervalInSeconds=600]
 */

export default initializeDB;
