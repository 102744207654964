import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import RxdbCollectionName from '../rxdbCollectionName';
import { generatePushQuery } from '../rxdbUtilityFunctions';

export interface BorerShiftComment extends BaseEntity {
  readonly borerShiftCommentTypeId: string;
  readonly borerShiftId: string;
  readonly comment: string;
}

export interface ExtendedBorerShiftComment extends BorerShiftComment {
  readonly parsedComment?: string | JSX.Element | JSX.Element[];
}

export type BorerShiftCommentCollection = RxCollection<BorerShiftComment> | null;
export type BorerShiftCommentDocument = RxDocument<BorerShiftComment>;

export const borerShiftCommentSchema: RxJsonSchema<BorerShiftComment> = {
  type: 'object',
  version: 3,
  description: 'describes a borer shift comment object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    version: { type: 'number' },
    updatedAt: {
      type: 'number',
      multipleOf: 1,
      minimum: 1,
      maximum: 9999999999999,
    },
    borerShiftId: { type: 'string' },
    borerShiftCommentTypeId: {
      type: 'string',
      ref: RxdbCollectionName.BORER_SHIFT_COMMENT_TYPE,
    },
    comment: { type: 'string' },
  },
  indexes: ['updatedAt'],
};

export const borerShiftCommentMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
  2: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
  3: (oldDoc: BorerShiftCommentDocument) => ({
    ...oldDoc,
  }),
};

const borerShiftCommentFeedQuery = print(gql`
  query borerShiftCommentFeed($borerEquipmentId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftCommentFeed(
      lastUpdateAt: $lastUpdateAt
      limit: $limit
      borerEquipmentId: $borerEquipmentId
    ) {
      borerShiftCommentTypeId
      borerShiftId
      id
      comment
      isDeleted
      updatedAt
      version
    }
  }
`);

export const borerShiftCommentPullQueryBuilder = generatePullQuery(
  borerShiftCommentFeedQuery,
  PullQueryContext.Borer,
);

const setBorerShiftCommentMutation = print(gql`
  mutation setBorerShiftComment($input: SetBorerShiftCommentInput) {
    setBorerShiftComment(input: $input) {
      id
      siteId
      shiftId
      borerEquipmentId
      borerShiftId
    }
  }
`);

type SetBorerShiftCommentInput = {
  id: string;
  borerShiftId: string;
  borerShiftCommentTypeId: string;
  comment: string;
  version: number;
  createdOn: string | null;
};

const mapBorerShiftCrewDocToInput = (doc: ExtendedBorerShiftComment): SetBorerShiftCommentInput => {
  return {
    id: doc.id,
    borerShiftId: doc.borerShiftId,
    borerShiftCommentTypeId: doc.borerShiftCommentTypeId,
    comment: doc.comment,
    version: doc.version,
    createdOn: null,
  };
};

export const borerShiftCommentPushQueryBuilder = generatePushQuery(
  setBorerShiftCommentMutation,
  (doc: ExtendedBorerShiftComment) => ({ input: mapBorerShiftCrewDocToInput(doc) }),
);
