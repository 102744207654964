import { i18n } from '@nutrien/cxp-components';
import { useEffect, useState } from 'react';

import { Advance } from '../../../../rxdb/Advance/queryBuilder';

interface ErrorObj {
  startFootage: string;
  endFootage: string;
  footage: string;
  numberOfBuckets: string;
}

export const startValidation = (advance: Advance) => {
  if (advance.startDistance === null || advance.startDistance === '') {
    return i18n.t('Required.');
  }

  if (
    Number(advance.startDistance) !== 0 &&
    (!Number(advance.startDistance) || Number(advance.startDistance) < 0)
  ) {
    return i18n.t('Invalid Distance.');
  }
  if (Number(advance.startDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }

  if (advance.startDistance.toString().length > 7) {
    return i18n.t('Must be 7 digit or less');
  }
  return '';
};

export const endValidation = (advance: Advance, isEven: boolean, isRocanville: boolean) => {
  if (advance.endDistance === null || advance.endDistance === '') {
    return i18n.t('Required');
  }
  if (
    Number(advance.endDistance) !== 0 &&
    (!Number(advance.endDistance) || Number(advance.endDistance) < 0)
  ) {
    return i18n.t('Invalid Distance.');
  }
  if (Number(advance.endDistance) % 1 !== 0) {
    return i18n.t('Must be a whole #');
  }

  if (isRocanville) return '';

  if (
    Number(advance.startDistance) &&
    isEven &&
    Number(advance.endDistance) > Number(advance.startDistance)
  ) {
    return i18n.t('Enter value ≤ start');
  }
  if (
    Number(advance.startDistance) &&
    !isEven &&
    Number(advance.endDistance) < Number(advance.startDistance)
  ) {
    return i18n.t('Enter value ≥ start');
  }

  if (advance.endDistance.toString().length > 7) {
    return i18n.t('Must be 7 digit or less');
  }

  return '';
};

export const isValidDistance = (distance: number | string | undefined | null): boolean => {
  if (distance !== '0' && distance !== 0 && !distance) {
    return false;
  }
  if (Number(distance < 0)) {
    return false;
  }
  if (!Number.isInteger(Number(distance))) return false;

  if (!RegExp('^[0-9]+$').test(`${distance}`)) return false;

  if (distance.toString().length > 7) return false;
  return true;
};

export const useValidation = (
  advance: Advance,
  showStartValidation: boolean,
  showEndValidation: boolean,
  isEven: boolean,
  isChevron: boolean,
  isRocanville: boolean,
): ErrorObj => {
  const [errors, setErrors] = useState<ErrorObj>({
    startFootage: '',
    endFootage: '',
    footage: '',
    numberOfBuckets: '',
  });

  useEffect(() => {
    if (!isChevron && showStartValidation) {
      setErrors(prev => ({ ...prev, startFootage: startValidation(advance) }));
    }
  }, [advance, showStartValidation, advance.endDistance, advance.startDistance, isChevron]);

  useEffect(() => {
    if (!isChevron && showEndValidation) {
      setErrors(prev => ({
        ...prev,
        endFootage: endValidation(advance, isEven, isRocanville),
      }));
    }
  }, [
    advance,
    showEndValidation,
    advance.startDistance,
    advance.endDistance,
    isEven,
    isChevron,
    isRocanville,
  ]);

  useEffect(() => {
    if (isChevron) {
      setErrors(prev => ({
        ...prev,
        footage:
          advance?.distance?.toString().length > 7
            ? 'Must be 7 digits or less'
            : isValidDistance(advance.distance)
            ? ''
            : 'Invalid Distance.',
      }));
    }
  }, [advance, advance.distance, isChevron]);

  useEffect(() => {
    setErrors(prev => ({
      ...prev,
      numberOfBuckets: isValidDistance(advance.numberOfBuckets) ? '' : 'Must be a valid number',
    }));
  }, [advance, advance.numberOfBuckets]);

  return errors;
};

export default useValidation;
