import { print } from 'graphql';
import gql from 'graphql-tag';
import type { MigrationStrategies, RxCollection, RxDocument, RxJsonSchema } from 'rxdb';

import { BaseEntity } from '../../models/BaseEntity';
import { generatePullQuery, PullQueryContext } from '../queryBuilders/generatePullQuery';
import { defaultDocAllTime } from '../Shared/defaultDoc';

export interface BorerShiftCommentType extends BaseEntity {
  readonly description: string;
  readonly isActive: boolean;
  readonly displayOrder: number;
}
export type BorerShiftCommentTypeCollection = RxCollection<BorerShiftCommentType> | null;
export type BorerShiftCommentTypeDocument = RxDocument<BorerShiftCommentType>;

export const borerShiftCommentTypeSchema: RxJsonSchema<BorerShiftCommentType> = {
  type: 'object',
  version: 3,
  description: 'describes a borer shift comment type object',
  primaryKey: 'id',
  properties: {
    id: { type: 'string', maxLength: 36 },
    isDeleted: { type: 'boolean' },
    isActive: { type: 'boolean' },
    description: { type: 'string' },
    version: { type: 'number' },
    updatedAt: { type: 'number' },
    displayOrder: { type: 'number' },
  },
};

export const borerShiftCommentTypeMigrationStrategies: MigrationStrategies = {
  1: (oldDoc: BorerShiftCommentTypeDocument) => ({
    ...oldDoc,
  }),
  2: (oldDoc: BorerShiftCommentTypeDocument) => ({
    ...oldDoc,
  }),
  3: (oldDoc: BorerShiftCommentTypeDocument) => ({
    ...oldDoc,
  }),
};

const borerShiftCommentTypeFeedQuery = print(gql`
  query borerShiftCommentTypeFeed($siteId: ID!, $lastUpdateAt: Float!, $limit: Int!) {
    borerShiftCommentTypeFeed(lastUpdateAt: $lastUpdateAt, limit: $limit, siteId: $siteId) {
      description
      id
      isActive
      isDeleted
      version
      displayOrder
      updatedAt
    }
  }
`);

export const borerShiftCommentTypePullQueryBuilder = generatePullQuery(
  borerShiftCommentTypeFeedQuery,
  PullQueryContext.Site,
  defaultDocAllTime,
);
